<template>
  <div class="popup" :class="classes">
    <span @click="close" class="popup__close"></span>
    <div class="popup__content">
      <router-view />
    </div>
  </div>
</template>

<script>
import "./popup.scss";

export default {
  name: "Popup",
  computed: {
    classes() {
      return {
        "popup_full-content": this.$route.meta.fullContent,
      };
    },
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
